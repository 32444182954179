import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";

export const frontmatter = {
  title: "Día -04",
  week: "Semana 0",
  day: "09",
  month: "Mar",
  monthNumber: "03",
  date: "2020-03-09",
  path: "/cronologia/semana-0#dia-09-mar",
};
const Day09M = ({props }) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDataEu fecha={frontmatter.date} />
        <ModCovidHighlight>
          Albania se suma a la lista de países contagiados.
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, hasta el momento se han registrado 410 más
          que el día anterior y 17 fallecidos (1,7% de los casos positivos). El
          total de personas en UCI asciende a 68.
        </ModText>
        <ModText>
          Los fallecidos por la enfermedad de Coronavirus COVID-19 hasta la
          fecha son 16 personas, registrándose el primer caso de fallecimiento
          en la Comunidad Valenciana el 13 de febrero, y los 6 últimos el día 8
          de marzo (3 en Madrid y 3 en el País Vasco).
        </ModText>
        <ModCovidHighlight>
          Murcia declara sus primeros casos confirmados.
        </ModCovidHighlight>

        <ModText>
          El{" "}
          <strong>
            Consejo Interterritorial del Sistema Nacional de Salud,
          </strong>{" "}
          del que forman parte el Ministerio de Sanidad y las comunidades
          autónomas, han acordado un{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/sanidad14/Paginas/2020/090320-interterritorial.aspx">
            conjunto de medidas
          </InlineLink>{" "}
          para zonas que se encuentren en fase de transmisión comunitaria
          significativa.
        </ModText>
        <ModImage
          src="/images/svg/09_mar_3_img_juntas.svg"
          alt="Suspension de clases | Teletrabajo | Limitar salidas hogar"
        />

        <ModText>
          En las <strong>zonas de transmisión significativa</strong> - Madrid,
          La Rioja, Vitoria y Labastida - han quedado suspendidas, además, las
          actividades colectivas en espacios cerrados con un aforo superior a
          mil personas. En caso de que estas actividades tengan lugar en
          espacios con un aforo menor, podrán celebrarse únicamente con una
          ocupación máxima de un tercio.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/sanidad14/Paginas/2020/090320-interterritorial.aspx"
            name="Medidas establecidas por el Consejo Interterritorial del
            Sistema Nacional de Salud"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day09M;
